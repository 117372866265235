Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getApiMethod = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjectTemplates";
exports.labelBodyText = "ProjectTemplates Body";
exports.projectAPiEndPoint = "/bx_block_catalogue/studies/study_selection";
exports.StrongemailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
exports.btnExampleTitle = "CLICK ME";
exports.phoneNumberRegex =  /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/ ;
exports.nameRegex = /^[a-zA-Z\s]+$/;
exports.getUserAccountApiEndPoint = "profile/user_profiles"
exports.passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
exports.changePasswordApiEndPoint = "bx_block_profile/new_password_set"
exports.putApiMethod = "PUT";
exports.getTermsAndConditionApiEndPoint = "bx_block_terms_and_conditions/terms_and_conditions"
exports.apiContentType = "application/json";
exports.getNewPrivacyPolicyApiEndPoint = "bx_block_content_management/privacy_policies"
exports.getFaqsApiEndPoint = "bx_block_content_management/faqs"
exports.patchApiMethod = "PATCH"
exports.editUserProfileApiEnd = "bx_block_profile/profiles/update_profile"
exports.changeEmailApiEndPoint = "bx_block_profile/change_email"
exports.postApiMethod="POST"
exports.getStudyApiEndPoint = "bx_block_catalogue/studies/study_selection"
exports.getContactUsApiEndPoint = "bx_block_contact_us/contacts"
// Customizable Area End
import React from "react";
// Customizable Area Start
import {
  Container,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  FormLabel,
  AccordionDetails,
  Typography, 
  AccordionSummary, 
  Box, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Divider, 
  Drawer, 
  Grid, 
  Hidden, 
  IconButton, 
  InputAdornment, 
  NativeSelect, 
  Paper, 
  styled, 
  BoxProps, 
  DrawerProps,
  Accordion,
} from "@material-ui/core";
import { createTheme} from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Dashborad, DashboradIcon, Patient, PatientsIcon, buttonCancel, callLogo, contactEmailLogo, contactPhoneLogo, dropdownLogo, editLogo, emailLogo, headerLogo, humanLogo, locationLogo, lockLogo, rightArrow, rightLogo, sponcer, userLogo } from "./assets";
export const configJSON = require("./config");
import LandingPage from ".././../landingpage/src/LandingPage.web"
import Dashboard from ".././../landingpage/src/Dashboard.web"
import SideNavbar from "../../../blocks/projecttemplates/src/SideNavbar.web";
import 'react-phone-input-2/lib/style.css'

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

import CustomisableUserProfilesController, {
  Props,
  IField
} from "./CustomisableUserProfilesController";
// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    )
      return "number";
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    )
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.value, field.field_type)
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true
            }}
            // defaultValue='Enter Value'
          />
        </div>
      );

    if (field.field_type === "date" || field.field_type === "datetime")
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(this.state.profile[field.name] as string).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date:Date) => this.changeFormValue(field.name, date, field.field_type)}
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );

    if (field.field_type === "checkbox")
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.checked, field.field_type)
            }
            color='primary'
          />
          {field.title}
        </div>
      );
    if (field.field_type === "boolean")
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(field.name, event.target.value, field.field_type);
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel value={"true"} control={<Radio />} label='True' />
              <FormControlLabel value={"false"} control={<Radio />} label='False' />
            </RadioGroup>
          </FormControl>
        </div>
      );
  };

  // Customizable Area Start

 
// Customizable Area End
  render() {
    // Customizable Area Start
    const BoxView = Box as React.FC<BoxProps>
    return (
        <ModalStyle>
       <SideNavbar showStudy={true} navigation={this.props.navigation} id={this.props.id} />
        <Box style={{display:"flex"}}>
            <Grid className="mainSideBar">
                <Grid>
                    <BoxView className="dashborad">
                    <BoxView className={this.state.activePage === "dashboard" ? "subDashboard" : "subDashboardTwo"}>
                        <img src={this.state.activePage === 'dashboard' ? Dashborad : DashboradIcon} height={24} />
                        <Typography className={this.state.activePage === "dashboard" ? "dashboradTextTwo" : "dashboradText"} id="dashboard" onClick={() => this.handleDrawer('dashboard')}>Dashboard</Typography>
                    </BoxView>
                    </BoxView>

                    <BoxView className="Patients">
                        <BoxView className={this.state.activePage === "patients" ? "subPatients" : "subPatientsTwo"}>
                            <img src={this.state.activePage === "patients" ? PatientsIcon : Patient} height={24} />
                            <Typography id="patients" className={this.state.activePage === "patients" ? "PatientsText" : "patientTextTwo"} onClick={() => this.handleDrawer('patients')}>Patients</Typography>
                        </BoxView>
                    </BoxView>
                </Grid>
            </Grid>
            <Box style={{ height: "auto", width: "100%" }}>
            {this.state.activePage === 'dashboard' ? <Dashboard navigation={this.props.navigation} id={this.props.id} /> : <LandingPage navigation={this.props.navigation} id={this.props.id} />}
        </Box>
        </Box>
        <DrawerStyle
            anchor="right"
            open={this.state.open}
            onClose={this.handleDrawerClose}
            data-test-id="drawer-close-button"
            style={{
                width: 470
            }}
        >
         
        </DrawerStyle>
      
    </ModalStyle>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const DrawerStyle = styled(Drawer)<DrawerProps>({
    top: '89px !important',
    height: "calc(100vh - 90px)",
    "& .MuiDrawer-paper": {
        borderRadius: '10px 0 0 10px',
    },
    "& .MuiDrawer-paper, .MuiBackdrop-root": {
        top: '90px !important',
        height: "calc(100vh - 90px)",
    }
})

const ModalStyle = styled((Box as React.FC<BoxProps>))({
    "& .studySelectGrp": {
        color: "#000",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        cursor: 'pointer',
    },
    "& .headerLogoMain" : {
        display:"flex",
        alignItems:"center"
    },
    "& .main-header-top": {
        padding: 20,
        position: 'sticky', top: 0, left: 0, right: 0, background: "#FFF",
        borderBottom: "1px solid rgba(161, 161, 161, 0.50)",
        zIndex:1
    },
    "& .main-logo": {
        width: 145
    },
    "& .headerLogo": {
        verticalAlign: 'middle',
        marginRight: '12px'
    },
    "& .dropdownLogo": {
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    "& .userName": {
        color: "#000",
        fontFamily: "Jost",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 500
    },
    "& .mainSideBar": {
        backgroundColor: "#5E2A95",
        width: "210px",
        borderRadius: "0px 12px 12px 0px"
    },
    "& .dashborad": {
        display: "flex",
        justifyContent: "center",
        paddingTop: "40px",
    },
    "& .dashboradText": {
        color: "#fff",
        textAlign: "center" as const,
        fontWeight: 600,
        marginLeft: "10px",
        cursor:"pointer"
    },
    "& .dashboradTextTwo": {
        color: "#A046FB",
        textAlign: "center" as const,
        fontWeight: 600,
        marginLeft: "10px",
        cursor:"pointer"

    },
    "& .PatientsText": {
        color: "#A046FB",
        textAlign: "center" as const,
        fontWeight: 600,
        marginLeft: "10px",
        cursor:"pointer"

    },
    "& .patientTextTwo" : {
        color: "white",
        textAlign: "center" as const,
        fontWeight: 600,
        marginLeft: "10px",
        cursor:"pointer"

    },
    "& .Patients": {
        paddingTop: "40px",
    },
    "& .subPatients": {
        display: "flex",
        justifyContent: "center",
        padding: "22px",
        backgroundColor: "#fff",
        textAlign: "center" as const,
        borderRadius: "0px 20px 20px 0px",
        marginRight: "25px"
    },
    "& .subPatientsTwo": {
        display: "flex",
        justifyContent: "center",
        padding: "22px",
        textAlign: "center" as const,
        borderRadius: "0px 20px 20px 0px",
        marginRight: "25px",
    },
    "& .subDashboardTwo": {
        display: "flex",
        justifyContent: "center",
        padding: "22px",
        textAlign: "center" as const,
        borderRadius: "0px 20px 20px 0px",

    },
    "& .subDashboard": {
        display: "flex",
        justifyContent: "center",
        padding: "22px",
        backgroundColor: "#fff",
        textAlign: "center" as const,
        borderRadius: "0px 20px 20px 0px",
        marginRight: "25px",
    },
})



// Customizable Area End
